@import url(fonts.css);
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

html {
  height: 100%; }

body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  background-color: #f7f7f7;
  color: #002040; }

img {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block; }

.underline {
  text-decoration: underline; }

#main {
  margin-left: auto;
  margin-right: auto;
  max-width: 940px; }
  #main .main-container {
    padding: 25px 10px 0 10px; }
  #main .logo-container {
    margin-bottom: 10px;
    text-align: center; }
    #main .logo-container .logo {
      display: inline-block; }
  #main .form-container {
    padding-top: 15px;
    padding-bottom: 15px; }
    #main .form-container .form-box {
      margin-bottom: 25px;
      padding: 30px 40px;
      background-color: #fff;
      box-shadow: 0 -1px 20px 0 #dfdfdf; }
      @media (max-width: 575.98px) {
        #main .form-container .form-box {
          padding: 25px; } }
  #main .h-title {
    font-weight: 300;
    color: #0341aa; }
  #main .default-login-title {
    line-height: 30px;
    margin-top: 20px; }
  #main .default-login-title.sm {
    line-height: 30px;
    margin-top: 0; }
  #main .qr-login-title {
    display: inline-block;
    line-height: 30px;
    margin-top: 20px; }
  #main .login-default form {
    padding-right: 10px; }
  #main .login-qrcode {
    border-left: 1px solid #a1a1a1; }
    #main .login-qrcode .qrcolumn {
      position: relative;
      padding-left: 10px;
      height: 100%; }
    #main .login-qrcode .helperbutton {
      display: inline-block;
      margin-top: 15px;
      float: right;
      border-radius: 25px;
      font-family: Roboto, sans-serif;
      font-size: 18px;
      font-weight: 700;
      padding: 9px 15px;
      background-color: #3898ec;
      color: #fff;
      border: 0;
      line-height: inherit;
      text-decoration: none;
      cursor: pointer; }
  #main .custom-input {
    display: block;
    width: 100%;
    height: 38px;
    padding: 8px 12px;
    margin-bottom: 10px;
    line-height: 1.428571429;
    color: #333;
    vertical-align: middle;
    box-shadow: none;
    border-style: none none solid;
    border-bottom-width: 1px;
    border-bottom-color: #bdbdbd;
    background-color: transparent;
    font-size: 16px; }
  #main .btn-normal {
    width: 100%;
    margin-top: 20px;
    padding: 10px;
    font-size: 18px;
    font-weight: 500;
    border-radius: 5px;
    background-color: #43c35e;
    color: #FFF; }
  #main .btn-login {
    width: 100%;
    margin-top: 20px;
    padding: 10px;
    font-size: 18px;
    font-weight: 500;
    border-radius: 5px;
    background-color: #43c35e;
    color: #FFF; }
  #main .btn-register {
    width: 100%;
    padding: 10px;
    font-size: 18px;
    font-weight: 400;
    border-radius: 5px;
    background-color: transparent;
    color: #0341aa;
    border: 2px solid #0341aa; }
  #main .btn-ok {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 0;
    background-color: #3bb354;
    color: #fff;
    border: 0;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer; }
  #main .or {
    display: block;
    text-align: center;
    color: #0341aa;
    padding: 10px 0;
    font-weight: 400;
    font-size: 19px;
    line-height: 24px; }
  #main .remind-password {
    display: block;
    text-align: center;
    color: #0341aa;
    padding: 10px 0;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px; }
  #main .helperpopup {
    position: absolute;
    display: none;
    text-align: center;
    width: 100%;
    height: 100%;
    padding: 20px;
    background-color: #c1c1c1;
    color: #fff; }
    #main .helperpopup .instruction {
      text-shadow: 0 1px 1px #636363;
      margin-bottom: 0; }
    #main .helperpopup img {
      padding: 8px 0; }
  #main .login-box {
    margin-top: 30px; }
    @media (max-width: 575.98px) {
      #main .login-box {
        margin-top: 0; } }
  #main .qrcode-img {
    display: inline-block;
    text-align: center;
    width: 100%;
    height: 220px;
    margin-top: 15px; }
  #main .form-validation {
    text-align: center;
    padding: 10px; }
  #main .form-validation.done {
    background-color: #dddddd; }
  #main .form-validation.fail {
    background-color: #ffdede; }

/*# sourceMappingURL=app.css.map */
